<template>
  <div>
    <!--begin::Row-->
    <!-- <div class="row">
        <div class="col-lg-12"> -->
            <div class="card card-custom card-stretch gutter-b">
                <!--begin::Header-->
                <div class="card-header border-0">
                    <h3 class="card-title font-weight-bolder text-dark">Pengaturan Ruangan</h3>
                    <div class="card-toolbar">
                        
                    </div>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-0">
                    <!--begin::Item-->
                    <div class="mb-12">
                        <!--begin::Content-->
                        <div>
                            <div class="row">
                                <div class="col-xl-8">
                                    <button type="button" class="btn btn-primary" size="sm" style="background-color:#030A8C; border-color: #030A8C; margin-top:10px" v-b-modal.modal-tambah><i class="flaticon2-plus"></i>Ruangan - Tim Penilai</button>
                                    <button type="button" class="btn btn-success" size="sm" style="background-color:green; border-color: green; margin-top:10px; margin-left:10px" v-b-modal.modal-wilayah><i class="flaticon2-plus"></i>Ruangan - Wilayah</button>
                                </div>
                                <div class="col-xl-4">
                                    
                                </div>
                          </div>
                            <br>
                            <div style="overflow-x:auto;">
                                <table id="example" class="table table-striped table-bordered" style="width:100%">
                                  <thead>
                                      <tr>
                                          <th>No</th>
                                          <th>Nama Ruangan</th>
                                          <th>Tim Penilai</th>
                                          <th>Wilayah</th>
                                          <th>Aksi</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr v-for="row in mapping" :key="row.id">
                                          <td></td>
                                          <td>{{row.nama_ruangan}}</td>
                                          <td><div v-for="penilai in row.penilai" :key="penilai">
                                              {{penilai}}
                                              </div>
                                          </td>
                                          <td>
                                              <div v-for="peserta in row.peserta" :key="peserta">
                                              {{peserta}}
                                              </div>
                                          </td>
                                          <td>
                                              <div>
                                                  <button data-toggle="modal" data-target="#modal-penilai" @click="getdaftarpeserta(row.id)" style="margin:5px; background-color:#030A8C; border-color: #030A8C;" class="btn btn-primary btn-sm" v-b-tooltip.hover title="Lihat">Lihat Tim</button>
                                                  <button data-toggle="modal" data-target="#modal-wilayah" @click="getdaftarwilayah(row.id)" style="margin:5px; background-color:green; border-color: green;" class="btn btn-success btn-sm" v-b-tooltip.hover title="Lihat">Lihat Wilayah</button>
                                                </div>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                            </div>
                        </div>
                        <!--end::Content-->
                    </div>
                    <!--end::Item-->
                </div>
                <!--end: Card Body-->
            </div>

            <!-- Tambah -->
            <b-modal
                v-model="show"
                id="modal-tambah"
                ref="modal"
                title="Pemetaan Ruangan dan Tim Penilai"
                @show="resetModal"
                @hidden="resetModal"
                @ok="handleOk"
                >
                <form ref="form" @submit.stop.prevent="tambahruangan">
                    <div class="form-group row">   
                        <label class="col-4">Nama Ruangan</label>
                        <div class="col-8">
                            <b-form-select v-model="selectedruangan" >
                            <option
                                v-for="(dataruangan, k) in ruangan"
                                :key="k"
                                :value="dataruangan"
                            >
                                {{ dataruangan.nama_ruangan }}
                            </option>
                            </b-form-select>
                            
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-4">Tim Penilai</label>
                        <div class="col-8">
                            <s-select multiple v-model="value" :options="timpenilai" :reduce="nama => nama.id" label="nama" />
                        </div>
                    </div>          
                </form>
                <template #modal-footer>
                    <div class="w-100">
                    <!-- <p class="float-left">Modal Footer Content</p> -->
                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        style="background-color: #030A8C; border-color: #030A8C; color:white"
                        @click="tambahtimpenilai"
                    >
                        Tambah
                    </b-button>
                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        style="margin-right: 10px;"
                        @click="show=false"
                    >
                        Tutup
                    </b-button>
                    </div>
                </template>
                </b-modal>

                <b-modal
                v-model="show1"
                id="modal-wilayah"
                ref="modal"
                size="lg"
                title="Pemetaan Ruangan dan Tim Penilai"
                @show="resetModal"
                @hidden="resetModal"
                @ok="handleOk"
                >
                <form ref="form" @submit.stop.prevent="tambahruangan">
                    <div class="form-group row">   
                        <label class="col-4">Nama Ruangan</label>
                        <div class="col-8">
                            <b-form-select v-model="selectedruangan" >
                            <option
                                v-for="(dataruangan, k) in ruangan"
                                :key="k"
                                :value="dataruangan"
                            >
                                {{ dataruangan.nama_ruangan }}
                            </option>
                            </b-form-select>
                            
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-4">Wilayah</label>
                        <div class="col-8">
                            <s-select multiple v-model="valuewilayah" :options="wilayah" :reduce="nama => nama.id" label="nama" />
                        </div>
                    </div>  
                    <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Tanggal</label>
                        <div class="col-lg-8">
                            <b-form-datepicker  v-model="tanggal" locale="in" class="mb-2"></b-form-datepicker>
                        </div>
                    </div>    
                    <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Rentang Waktu</label>
                        <div class="col-lg-8">
                            <div class="row">
                                <div class="col-xl-6">
                                    <!--begin::Input-->
                                    <div class="form-group">
                                        <b-form-timepicker
                                        v-model="waktu_mulai"
                                        id="timepicker-buttons"
                                        now-button
                                        reset-button
                                        locale="in"
                                        ></b-form-timepicker>
                                    </div>
                                    <!--end::Input-->
                                </div>
                                <div class="col-xl-6">
                                    <!--begin::Input-->
                                    <div class="form-group">
                                        <b-form-timepicker
                                        v-model="waktu_selesai"
                                        id="timepicker-buttons"
                                        now-button
                                        reset-button
                                        locale="in"
                                        ></b-form-timepicker>
                                    </div>
                                    <!--end::Input-->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">   
                        <label class="col-4">Status</label>
                        <div class="col-8">
                            <b-form-select v-model="selectedstatus" >
                            <option
                                v-for="(datastatus, k) in status_wilayah"
                                :key="k"
                                :value="datastatus.value"
                            >
                                {{ datastatus.text }}
                            </option>
                            </b-form-select>
                            
                        </div>
                    </div>     
                </form>
                <template #modal-footer>
                    <div class="w-100">
                   
                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        style="background-color: #030A8C; border-color: #030A8C; color:white"
                        @click="tambahwilayah"
                    >
                        Tambah
                    </b-button>
                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        style="margin-right: 10px;"
                        @click="show1=false"
                    >
                        Tutup
                    </b-button>
                    </div>
                </template>
                </b-modal>


                <!-- modal list daftar wilayah -->
                <div class="modal fade bd-example-modal-lg" id="modal-wilayah" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Daftar Wilayah</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                           <div>
                                <div style="overflow-x:auto;">
                                <table id="example2" class="display" style="width:100%">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Ruangan</th>
                                            <th>Wilayah</th>
                                            <th>Waktu Mulai</th>
                                            <th>Waktu Selesai</th>
                                            <th>Tanggal</th>
                                            <th>Status</th>
                                            <th>Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="row in daftarwilayah" :key="row.id">
                                            <td></td>
                                            <td>{{row.nama_ruangan}}</td>
                                            <td>
                                                <div v-for="peserta in row.nama_peserta" :key="peserta">
                                                    {{peserta.nama}}
                                                </div>

                                            </td>
                                            <td>
                                                {{row.waktu_mulai}}
                                            </td>
                                            <td>
                                                {{row.waktu_selesai}}
                                            </td>
                                            <td>
                                                {{row.tanggal}}
                                            </td>
                                            <td>
                                                {{row.status}}
                                            </td>
                                            <td>
                                                <div>
                                                    <button data-toggle="modal" data-target="#modal-editt" @click="getruanganwilayah(row.id)" style="margin:5px" class="btn btn-success" v-b-tooltip.hover title="Ubah"><i class="fa fa-edit"></i></button>
                                                    <button @click="deleteruanganwilayah(row.id, row.idruangan)" style="margin:5px" class="btn btn-danger"><i class="fa fa-trash" v-b-tooltip.hover title="Buang"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                        </div>
                        </div>
                </div>
            </div>

            <!-- modal list daftar penilai -->
            <div class="modal fade bd-example-modal-lg" id="modal-penilai" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Daftar Penilai</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                           <div>
                                <div style="overflow-x:auto;">
                                <table id="example3" class="display" style="width:100%">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Ruangan</th>
                                            <th>Penilai</th>
                                            <th>Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="row in daftarpenilai" :key="row.id">
                                            <td></td>
                                            <td>{{row.nama_ruangan}}</td>
                                            <td>
                                                <div v-for="peserta in row.nama_penilai" :key="peserta">
                                                    {{peserta.nama}}
                                                </div>

                                            </td>
                                            <td>
                                                <div>
                                                    <button data-toggle="modal" data-target="#modal-edit" @click="getruangan(row.id)" style="margin:5px" class="btn btn-success" v-b-tooltip.hover title="Ubah"><i class="fa fa-edit"></i></button>
                                                    <button @click="deleteruanganpenilai(row.id, row.idruangan)" style="margin:5px" class="btn btn-danger"><i class="fa fa-trash" v-b-tooltip.hover title="Buang"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                        </div>
                        </div>
                </div>
            </div>

                    <!-- Modal edit penilai -->
                <div class="modal fade" id="modal-edit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Ubah Ruangan</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                           <form ref="form" @submit.stop.prevent="tambahcatatan">
                                    <div class="form-group row">   
                        <label class="col-4">Nama Ruangan</label>
                        <div class="col-8">
                            <b-form-select v-model="getRuangan.idruangan" class="mb-3">
                                <b-form-select-option v-for="(value, name) in ruangan" @input="testt(value.nama_ruangan)" :key="name" :value="value.id">{{value.nama_ruangan}} </b-form-select-option>
                            </b-form-select>
                        </div>
                    </div>
                        <div class="form-group row">
                        <label class="col-4">Tim Penilai</label>
                        <div class="col-8">
                            <s-select multiple v-model="getRuangan.nama_penilai" :options="timpenilai" label="nama"/>
                        </div>
                    </div>   
                </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                            <button type="button" @click="editruangan(getRuangan.id)" style="background-color: #030A8C; border-color: #030A8C; color:white" data-dismiss="modal" class="btn btn-primary">Simpan</button>
                        </div>
                        </div>
                    </div>
                </div>

            <!-- modal edit wilayah -->
                <div class="modal fade" id="modal-editt" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Ubah Ruangan</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                           <form ref="form" @submit.stop.prevent="tambahcatatan">
                                    <div class="form-group row">   
                        <label class="col-4">Nama Ruangan</label>
                        <div class="col-8">
                            <b-form-select v-model="getPeserta.idruangan" class="mb-3">
                                <b-form-select-option v-for="(value, name) in ruangan" :key="name" :value="value.id">{{value.nama_ruangan}} </b-form-select-option>
                            </b-form-select>
                            
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-4">Wilayah</label>
                        <div class="col-8">
                            <s-select multiple v-model="getPeserta.nama_peserta" :options="wilayah" label="nama" />
                        </div>
                    </div> 
                    <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Tanggal</label>
                        <div class="col-lg-8">
                            <b-form-datepicker  v-model="getPeserta.tanggal" locale="in" class="mb-2"></b-form-datepicker>
                        </div>
                    </div>    
                    <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Rentang Waktu</label>
                        <div class="col-lg-8">
                            <div class="row">
                                <div class="col-xl-6">
                                    <!--begin::Input-->
                                    <div class="form-group">
                                        <b-form-timepicker
                                        v-model="getPeserta.waktu_mulai"
                                        id="timepicker-buttons"
                                        now-button
                                        reset-button
                                        locale="in"
                                        ></b-form-timepicker>
                                    </div>
                                    <!--end::Input-->
                                </div>
                                <div class="col-xl-6">
                                    <!--begin::Input-->
                                    <div class="form-group">
                                        <b-form-timepicker
                                        v-model="getPeserta.waktu_selesai"
                                        id="timepicker-buttons"
                                        now-button
                                        reset-button
                                        locale="in"
                                        ></b-form-timepicker>
                                    </div>
                                    <!--end::Input-->
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div class="form-group row">   
                        <label class="col-4">Status</label>
                        <div class="col-8">
                            <b-form-select v-model="getPeserta.status" class="mb-3">
                                <b-form-select-option v-for="(value, name) in status_wilayah" :key="name" :value="value.value">{{value.text}} </b-form-select-option>
                            </b-form-select>
                            
                        </div>
                    </div>
                </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                            <button type="button" @click="editruanganwilayah(getPeserta.id, getPeserta.idruangan)" style="background-color: #030A8C; border-color: #030A8C; color:white" data-dismiss="modal" class="btn btn-primary">Simpan</button>
                        </div>
                        </div>
                    </div>
                </div>

        </div>
    <!-- </div>
  </div> -->
</template>
<style>
  div.messages {
  /* background-color: lightblue; */
  /* width: 110px; */
  height: 500px;
  overflow: auto;
}
</style>
<style lang="">
  
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
import axios from 'axios';
import { VueEditor } from "vue2-editor";
// import Vue from 'vue'
import vSelect from 'vue-select'
Vue.component('s-select', vSelect)
export default {
  components:{VueEditor,vSelect},
  data() {
      return {
        test:'hai',
        url:localStorage.getItem('baseapi'),
        token: localStorage.getItem('id_token'),
        user: JSON.parse(localStorage.getItem('user')),
        tahun: new Date().getFullYear(),
        selectedruangan:'',
        selectedwilayah:'',
        selectedstatus:'',
        ruangan:[],
        mapping:[],
        wilayah:[],
        timpenilai:[],
        nama_ruangan:'',
        getRuangan:[],
        getPeserta:[],
        waktu_mulai:'',
        waktu_selesai:'',
        tanggal:'',
        status:'',
        wei:'',
        status_wilayah: [
         { value: 'Lock', text: 'Lock' },
         { value: 'Unlock', text: 'Unlock' },
       ],
        status_ruangan: [
         { value: 'Aktif', text: 'Aktif' },
         { value: 'Tidak Aktif', text: 'Tidak Aktif' },
       ],
       show:false,
       show1:false,
       value:[],
       valuewilayah:[],
       daftarwilayah:[],
       daftarpenilai:[],
       wilayah2:[]
      }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Pengaturan Ruangan" }]);
    this.getdata()
    this.getwilayah()
  },
  methods: {
    getwilayah(){
        // get daata provinsi
        axios.get(this.url+'/tabel_nomine_provinsi', {
                headers: {
                    'xth': this.token
                }
            }).then(response => {
            var provinsi = response.data.data;
            for (let index = 0; index < provinsi.length; index++) {
                this.wilayah.push({
                    id: parseInt(provinsi[index].idptsp),
                    nama:provinsi[index].daerah,
                })
                this.wilayah2.push({
                    id: parseInt(provinsi[index].idptsp),
                    name:provinsi[index].daerah,
                })
            }
        });
        // get daata kabupaten
        axios.get(this.url+'/tabel_nomine_kabupaten', {
                headers: {
                    'xth': this.token
                }
            }).then(response => {
            var kabupaten = response.data.data;
            for (let index = 0; index < kabupaten.length; index++) {
                this.wilayah.push({
                    id: parseInt(kabupaten[index].idptsp),
                    nama:kabupaten[index].daerah,
                })
                this.wilayah2.push({
                    id: parseInt(kabupaten[index].idptsp),
                    name:kabupaten[index].daerah,
                })
            }
        });
        // get daata kota
        axios.get(this.url+'/tabel_nomine_kota', {
                headers: {
                    'xth': this.token
                }
            }).then(response => {
            var kota = response.data.data;
            for (let index = 0; index < kota.length; index++) {
                this.wilayah.push({
                    id: parseInt(kota[index].idptsp),
                    nama:kota[index].daerah,
                })
                this.wilayah2.push({
                    id: parseInt(kota[index].idptsp),
                    name:kota[index].daerah,
                })
            }
        });
        // get daata kl
        axios.get(this.url+'/tabel_nomine_kl', {
                headers: {
                    'xth': this.token
                }
            }).then(response => {
            var kl = response.data.data;
            for (let index = 0; index < kl.length; index++) {
                this.wilayah.push({
                    id: parseInt(kl[index].idkl),
                    nama:kl[index].nama_kl,
                })
                this.wilayah2.push({
                    id: parseInt(kl[index].idkl),
                    name:kl[index].nama_kl,
                })
            }
        });
    },
    testt(nama){
        this.wei = nama;
        console.log(this.wei)
    },
    getdata(){
      $('#example').DataTable().destroy();
        this.loaddata()
    },
    loaddata(){
        var datapenilai = this.timpenilai
            while (datapenilai.length) {
               datapenilai.pop();
            }
      this.mapping = null,
        axios.get(this.url+"/mapping_ruangan_uji_petik", {
            headers: {
                'xth': this.token
            }
        })
        .then(response => {
            var ruangann = response.data.data
            this.mapping = ruangann;
            this.ruangan = ruangann;
            this.initDatatable();
        }).catch(error=>{
                return error
        });
        axios.get(this.url+"/users?filter=id_role,=,tim_penilai", {
                headers: {
                    'xth': this.token
                }
            })
        .then(response => {
            var result = response.data.data;
            for (let index = 0; index < result.length; index++) {
                    this.timpenilai.push({
                        id: parseInt(result[index].id),
                        nama:result[index].name,
                    })
            }
            this.gettimteknis()
      }).catch(error=>{
        console.log(error)
        return error
      });
            
    },
    gettimteknis(){
        axios.get(this.url+"/users?filter=id_role,=,tim_teknis_penilai", {
            headers: {
                'xth': this.token
            }
        })
        .then(response => {
            var result = response.data.data;
            for (let index = 0; index < result.length; index++) {
                    this.timpenilai.push({
                        id: parseInt(result[index].id),
                        nama:result[index].name,
                    })
            }
            // var result = response.data.data;
            // for(let i=0;i<result.length;i++){
            //     let temp = result[i]['alamat'].split("-");
            //     if(temp.length <= 1){
            //         continue;
            //     }
            //     this.timpenilai.push({
            //         id: parseInt(result[i].id),
            //         nama:result[i].name,
            //     })
            // }
        }).catch(error=>{
            console.log(error)
            return error
        });
    },
    resetModal() {
        this.nama_ruangan=''
        this.status='Aktif'
      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.tambahtimpenilai()
      },

    //   tambah timpenilai
      tambahtimpenilai() {
        var array = this.value;
        const idpenilai = array.join('||');
        const nama_ruangan = this.selectedruangan.nama_ruangan;
        const idruangan = this.selectedruangan.id;
        const tahun = this.tahun
        axios
            .post(this.url+"/tabel_mapping_penilai_ruangan_uji_petik",
            {
                nama_ruangan,
                idpenilai,
                idruangan,
                tahun
            }
            , {
                headers: {
                    'xth': this.token
                }
            })
            .then((res) => {
                this.$bvToast.toast('Berhasil Disimpan', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                this.value=[]
                this.getdata()
            return res;
            })
            .catch((err) => {
            this.$bvToast.toast('Gagal Disimpan', {
                title: `Failed `,
                variant: `danger`,
                solid: true
            })
            return err;
        });
        // Push the name to submitted names
        // this.submittedNames.push(this.name)
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-tambah')
        })
    },

    // tambah wilayah
    tambahwilayah() {
        var array = this.valuewilayah;
        const idpeserta = array.join('||');
        const nama_ruangan = this.selectedruangan.nama_ruangan;
        const idruangan = this.selectedruangan.id;
        const tanggal = this.tanggal;
        const waktu_mulai = this.waktu_mulai;
        const waktu_selesai = this.waktu_selesai;
        const status = this.selectedstatus;
        const tahun = this.tahun
        axios
            .post(this.url+"/tabel_mapping_peserta_ruangan_uji_petik",
            {
                nama_ruangan,
                idpeserta,
                idruangan,
                tanggal,
                waktu_mulai,
                waktu_selesai,
                status,
                tahun
            }
            , {
                headers: {
                    'xth': this.token
                }
            })
            .then((res) => {
                this.$bvToast.toast('Berhasil Disimpan', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                this.getdata()
            return res;
            })
            .catch((err) => {
            console.log(err)
            this.$bvToast.toast('Gagal Disimpan', {
                title: `Failed `,
                variant: `danger`,
                solid: true
            })
            return err;
        });
        // Push the name to submitted names
        // this.submittedNames.push(this.name)
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-wilayah')
        })
    },
    // dapatkan detail penilai
    getruangan(id){
        axios.get(this.url+"/tabel_mapping_peserta_ruangan_uji_petik?filter=id,=,"+id, {
            headers: {
                'xth': this.token
            }
        }).then(response => {
            this.getRuangan = response.data.data[0];
        });
    },
    // dapatkan detail wilayah
    getruanganwilayah(id){
        axios.get(this.url+"/tabel_mapping_peserta_ruangan_uji_petik?filter=id,=,"+id, {
            headers: {
                'xth': this.token
            }
        }).then(response => {
            this.getPeserta = response.data.data[0];
        });
    },

    // hapus data wilayah ptsp
    deleteruanganwilayah(id, idruangan){
        axios.delete(this.url+`/tabel_mapping_peserta_ruangan_uji_petik/${id}`,
            {
                headers: {
                    "Content-type": "application/json",
                    "xth": this.token,
                }
            })
            .then((res) => {
                this.$bvToast.toast('Berhasil Dihapus', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                this.getdaftarwilayah(idruangan)
                this.getdata()
            return res;
            })
            .catch((err) => {
            console.log(err)
            this.$bvToast.toast('Gagal Dihapus', {
            title: `Failed `,
            variant: `danger`,
            solid: true
            })
            return err;
        });
    },

    // hapus data penilai
    deleteruanganpenilai(id, idruangan){
        axios.delete(this.url+`/tabel_mapping_penilai_ruangan_uji_petik/${id}`,
            {
                headers: {
                    "Content-type": "application/json",
                    "xth": this.token,
                }
            }
            )
            .then((res) => {
                this.$bvToast.toast('Berhasil Dihapus', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                this.getdaftarpeserta(idruangan)
                this.getdata()
            return res;
            })
            .catch((err) => {
            console.log(err)
            this.$bvToast.toast('Gagal Dihapus', {
            title: `Failed `,
            variant: `danger`,
            solid: true
            })
            return err;
        });
    },
    // mendapatkan data wilayah per ruangan
    getdaftarwilayah(id){
        $('#example2').DataTable().destroy();
        this.daftarwilayah = null,
        axios.get(this.url+"/tabel_mapping_peserta_ruangan_uji_petik?filter=idruangan,=,"+id, {
                headers: {
                    'xth': this.token
                }
            }).then(response => {
            var daftarwilayahh = response.data.data;
            this.daftarwilayah = daftarwilayahh;
            this.initDatatable2()
        });
    },

    // mendapatkan data penilai per ruangan
    getdaftarpeserta(id){
        $('#example3').DataTable().destroy();
        this.daftarpenilai = null,
        axios.get(this.url+"/tabel_mapping_penilai_ruangan_uji_petik?filter=idruangan,=,"+id, {
                headers: {
                    'xth': this.token
                }
            }).then(response => {
            var daftarpenilaii = response.data.data;
            this.daftarpenilai = daftarpenilaii;
            this.initDatatable3()
        });
    },

    // edit data penilai ruangan
    editruangan(id) {
        var nama_ruang = '';
        var i = 0;
        for (i in this.ruangan) {
            if (this.ruangan[i].id == this.getRuangan.idruangan) {
                nama_ruang = this.ruangan[i].nama_ruangan;
                console.log(this.ruangan[i]); // {a: 5, b: 6}
            }
        }
        var data = [];
        data = this.getRuangan.nama_penilai;
        const arrayuser=[];
        for(var i in data){
            arrayuser.push(data[i].id);
        }
        console.log(arrayuser);
        const idUser = arrayuser.join('||');
        console.log(idUser);
        axios.put(this.url+`/tabel_mapping_penilai_ruangan_uji_petik/${id}`,
            {
                // idPemda,
                nama_ruangan: nama_ruang,
                idruangan:this.getRuangan.idruangan,
                idpenilai:idUser,
                tahun:this.tahun
            }
            , {
                headers: {
                    'xth': this.token
                }
            })
            .then((res) => {
                this.$bvToast.toast('Berhasil Diedit', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                this.getdata()
            return res;
            })
            .catch((err) => {
            console.log(err)
            this.$bvToast.toast('Gagal Diedit', {
                title: `Failed `,
                variant: `danger`,
                solid: true
            })
            return err;
        });
        // Push the name to submitted names
        // this.submittedNames.push(this.name)
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-prevent-closing')
        })
    },

    // edit data wilayah ruangan
    editruanganwilayah(id, idruangan) {
        var nama_ruang = '';
        var i = 0;
        for (i in this.ruangan) {
            if (this.ruangan[i].id == this.getPeserta.idruangan) {
                nama_ruang = this.ruangan[i].nama_ruangan;
                console.log(this.ruangan[i]); // {a: 5, b: 6}
            }
        }
        console.log(nama_ruang);
        //console.log(this.getPeserta.nama_penilai);
        var data = [];
        data = this.getPeserta.nama_peserta;
        // console.log(data)
        const arrayuser=[];
        for(var i in data){
            arrayuser.push(data[i].id);
        }
        console.log(arrayuser);
        const idUser = arrayuser.join('||');
        console.log(idUser);
        axios.put(this.url+`/tabel_mapping_peserta_ruangan_uji_petik/${id}`,
            {
                // idPemda,
                nama_ruangan:nama_ruang,
                idruangan:this.getPeserta.idruangan,
                idpeserta:idUser,
                tanggal:this.getPeserta.tanggal,
                waktu_mulai:this.getPeserta.waktu_mulai,
                waktu_selesai:this.getPeserta.waktu_selesai,
                status:this.getPeserta.status,
                tahun: this.tahun
            }
            , {
                headers: {
                    'xth': this.token
                }
            })
            .then((res) => {
                this.$bvToast.toast('Berhasil Diedit', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                console.log(res);
                this.getdaftarwilayah(idruangan)
                this.getdata()
                // localStorage.setItem('idprofil', JSON.stringify(res.data.id))
            return res;
            })
            .catch((err) => {
            console.log(err)
            this.$bvToast.toast('Gagal Diedit', {
            title: `Failed `,
            variant: `danger`,
            solid: true
            })
            return err;
        });
        // Push the name to submitted names
        // this.submittedNames.push(this.name)
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-prevent-closing')
        })
    },
    initDatatable() {
        setTimeout(() => {
        var t =  $('#example').DataTable({
            "pagingType": "full_numbers",
            "language": {
                "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                "paginate": {
                    "first":      "Awal",
                    "last":       "Akhir",
                    "next":       "Selanjutnya",
                    "previous":   "Sebelumnya"
                },
            },
            "lengthMenu": [
                [10, 25, 50, -1],
                [10, 25, 50, "All"]
            ],
            responsive: true,
            destroy: true,
            retrieve: true,
            autoFill: true,
            colReorder: true,
        });
        t.on( 'order.dt search.dt', function () {
        t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
            cell.innerHTML = i+1;
        } );
        } ).draw();
        }, 300)
    },
    initDatatable2() {
        setTimeout(() => {
        var t =  $('#example2').DataTable({
            "pagingType": "full_numbers",
            "language": {
                "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                "paginate": {
                    "first":      "Awal",
                    "last":       "Akhir",
                    "next":       "Selanjutnya",
                    "previous":   "Sebelumnya"
                },
            },
            "lengthMenu": [
                [10, 25, 50, -1],
                [10, 25, 50, "All"]
            ],
            responsive: true,
            destroy: true,
            retrieve: true,
            autoFill: true,
            colReorder: true,
        });
        t.on( 'order.dt search.dt', function () {
        t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
            cell.innerHTML = i+1;
        } );
        } ).draw();
        }, 300)
    },
    initDatatable3() {
        setTimeout(() => {
        var t =  $('#example3').DataTable({
            "pagingType": "full_numbers",
            "language": {
                "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                "paginate": {
                    "first":      "Awal",
                    "last":       "Akhir",
                    "next":       "Selanjutnya",
                    "previous":   "Sebelumnya"
                },
            },
            "lengthMenu": [
                [10, 25, 50, -1],
                [10, 25, 50, "All"]
            ],
            responsive: true,
            destroy: true,
            retrieve: true,
            autoFill: true,
            colReorder: true,
        });
        t.on( 'order.dt search.dt', function () {
        t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
            cell.innerHTML = i+1;
        } );
        } ).draw();
        }, 300)
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }
      // set current active tab
      event.target.classList.add("active");
      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  },
};
</script>